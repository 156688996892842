import React from 'react'
import { graphql } from "gatsby"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import TeamDetails from '../components/TeamDetails/TeamDetails'
import SiteBreadcrumbs from '../components/Breadcrumb/site-breadcrumbs'
import SEO from '../components/Seo/seo'
const NewsDetailTemplate = ({ data }) => {
  const team = data.glstrapi.team
  return (
    <>
      <SEO
        title={team.Meta_Title ? team.Meta_Title : team.Name +' | '+team.Designation}
        description={team.Meta_Description ? team.Meta_Description : `Get to know about ${team.Name} here. Contact one of estate agents for assistance in finding the right properties for you.`}
      />
      <Header/>
          <SiteBreadcrumbs style="default-breadcrumbs"  alias="meet-the-team" menulabel={team.Name}/>
          <TeamDetails team={team} />
      <Footer/>
    </>
  )
}

export default NewsDetailTemplate

export const pageQuery = graphql`
  query GetTeamPage($articleId: ID!) {
    glstrapi {
      team(id: $articleId, publicationState: LIVE) {
          id
          Name
          Email
          URL
          Designation
          Bio
          Image {
            url
            alternativeText
          }
          imagetransforms
        }
    }
  }
`


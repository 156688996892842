import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import parse from "html-react-parser"
import './TeamDetails.scss'
import GetGGFXImage from '../common/site/get-ggfx-image'
import { Link } from 'gatsby'
const TeamDetails = ({ team }) => {
    let processedImages = JSON.stringify({});
    if (team?.imagetransforms?.Image_Transforms) {
    processedImages = team.imagetransforms.Image_Transforms;
    }    
    return (    
        <section className='section-p grey-bg team-details'>
            <Container className='team-detail-container'>
            <Row className='team-row'>
            <Col lg={7} className="team-content-wrap">
                <h1>{team.Name}</h1>            
                <div className='team-designation'>{team.Designation}</div>
                <Link to={`/contact-us/`} state={{teamemail: team.Email,staffname: team.Name}} className='btn btn-fill-primary'>Email Me</Link>
                {team.Bio && parse(team.Bio)}
            </Col>
            <Col lg={5} className="team-image-wrap">
                <div className='rounded'>
                    <GetGGFXImage imagename={"teams.Image.detailimg"} imagesource={team.Image} fallbackalt={team.Name +' - '+team.Designation} imagetransformresult={processedImages} id={team.id}/>    
                    {/* <img src={team.Image.url} alt="" /> */}
                </div>
            </Col>
            </Row>
            </Container>
        </section>
    )
}

export default TeamDetails